import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import HamburgerIcon from "src/components/Hamburger"
import { useMenu } from "src/helpers/menu"

const Container = styled.header`
  text-align: right;
  position: relative;
`
const Strip = styled.div`
  background-color: black;
  position: relative;
  z-index: 20;
  height: 50px;
`
const MenuButton = styled.button`
  color: white;
  padding: 12px 18px 15px;
  text-transform: uppercase;
  svg {
    vertical-align: -6px;
    fill: white;
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
  &:active {
    color: black;
    background-color: white;
    svg {
      fill: black;
    }
  }
  @media (hover: hover) {
    &:hover {
      color: black;
      background-color: white;
      svg {
        fill: black;
      }
    }
  }
`
const Menu = styled.ul<{ $open: boolean }>`
  position: absolute;
  z-index: 10;
  right: 0;
  top: ${({ $open }) => ($open ? 50 : -100)}px;
  transition: top 450ms ease-in-out;
  background-color: black;
  list-style-type: none;
  padding: 0 0 0;
  margin: 0;
  border-bottom-left-radius: 8px;
  overflow: hidden;
  li {
    a {
      text-transform: uppercase;
      display: block;
      padding: 8px 18px;
      text-decoration: none;
      &:hover,
      &:active {
        color: black;
        background-color: white;
      }
    }
  }
`

const Header: React.FC = () => {
  const { openMenu, setOpenMenu, ref } = useMenu()

  return (
    <Container>
      <Strip>
        <MenuButton onClick={() => setOpenMenu(!openMenu)} ref={ref}>
          <HamburgerIcon /> Menu
        </MenuButton>
      </Strip>
      <Menu $open={openMenu}>
        <li>
          <Link to="/drone/">Drone Work</Link>
        </li>
        <li>
          <Link to="/broadcast/">Live broadcast (COMING SOON)</Link>
        </li>
        <li>
          <Link to="/musicvideo/">Music Video </Link>
        </li>
      </Menu>
    </Container>
  )
}

export default Header
