import { useEffect, useRef, useState } from "react"

export const useMenu = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const ref = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const handleClickAway = ({ target }: MouseEvent | TouchEvent) => {
      if (!ref.current?.contains(target as Node)) {
        setOpenMenu(false)
      }
    }

    // https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/ClickAwayListener/ClickAwayListener.js
    document.addEventListener("touchstart", handleClickAway)
    document.addEventListener("click", handleClickAway)
    return () => {
      document.removeEventListener("touchstart", handleClickAway)
      document.removeEventListener("click", handleClickAway)
    }
  }, [])

  return { openMenu, setOpenMenu, ref }
}
